import React from "react"
import { withStyles } from "@material-ui/core/styles"
import InfoLayout from "../components/info-layout"
import SEO from "../components/seo"

const styles = theme => ({
  root: {
    paddingBottom: 64,
  }
});

const ContactPage = ({classes}) => {
  return (
    <InfoLayout>
      <SEO title="Contact" keywords={['contact']} />
      <div className={classes.root}>
        <h1>Contact Us</h1>
        <p>Have a question or comment?</p>
        <p>Contact us at <a href='mailto:info@searchswift.net'>info@searchswift.net</a></p>
        <hr/>
        <h2>Advertise With Us</h2>
        <p>If you are interested in advertising on our platform you can reach us below for more info:</p>
        <p><a href='mailto:advertise@searchswift.net'>advertise@searchswift.net</a></p>
      </div>
    </InfoLayout>
  );
}

export default withStyles(styles)(ContactPage);
